import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorHandlingService } from '../../_services/error-handling.service';
import { Observable } from 'rxjs';

export interface ChildData {
  id: string;
  name: string;
  email: string;
  mobile: string;
  status: string;
}
export class Book {
  constructor(public _id: string, public title: string) {}
}

export interface IBookResponse {
  total: number;
  results: Book[];
}

@Injectable({
  providedIn: 'root'
})

export class ChildService {

  apiUrl: any = environment.apiUrl + '/api';


  // Header
  appAdmin: any = JSON.parse(localStorage.getItem('appAdmin'));
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'x-auth-token': this.appAdmin.token
  });

  constructor(
    public errorHandlingService: ErrorHandlingService,
    public httpClient: HttpClient
  ) { }
  
  bookSearch(filter: {title: string} = {title: ''}, page = 1): Observable<IBookResponse> {
    const params = new HttpParams()
      .set('search', `${filter.title}`);
    return this.httpClient.get<IBookResponse>(this.apiUrl + '/book/search', {params, headers: this.headers })
    .pipe(
      tap((response: IBookResponse) => {
        response.results = response['data'].map(book => new Book(book._id, book.title));
        return response;
      })
      );
  }

  list(id: any) {
    return this.httpClient.get(this.apiUrl + '/child/parent/' + id, { headers: this.headers })
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error , ''))
      );
  }

  preference() {
    return this.httpClient.get(this.apiUrl + '/child/preference', { headers: this.headers })
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error , ''))
      );
  }
  
  details(id: any) {
    return this.httpClient.get(this.apiUrl + '/child/' + id, { headers: this.headers })
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }
  
  progress(id: any) {
    return this.httpClient.get(this.apiUrl + '/child/progress/' + id, { headers: this.headers })
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }

  updateStatus(id: any, updatedStatus: any) {
    return this.httpClient.get(this.apiUrl + '/child/update-status/' + id + '/' + updatedStatus, { headers: this.headers })
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }
  update(id: any, updatedData: any) {
    return this.httpClient.put(this.apiUrl + '/child/' + id, updatedData, { headers: this.headers })
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }
  create(id: any, updatedData: any) {
    return this.httpClient.post(this.apiUrl + '/child/' + id, updatedData, { headers: this.headers })
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }

}
