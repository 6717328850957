import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { ErrorHandlingService } from '../../_services/error-handling.service';

export interface PageData {
  id: string;
  name: string;
  email: string;
  city_name: string;
  quantity: string;
  amount: string;
  created_at: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  apiUrl: any = environment.apiUrl + '/api';

  // Header
  appAdmin: any = JSON.parse(localStorage.getItem('appAdmin'));
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'x-auth-token': this.appAdmin.token
  });

  constructor(
    public errorHandlingService: ErrorHandlingService,
    public httpClient: HttpClient
  ) { }

  list(pageParams) {
    const params = new HttpParams()
      .set('orderBy', `${pageParams.orderBy}`)
      .set('orderDir', `${pageParams.orderDir}`)
      .set('pageNumber', `${pageParams.offset}`)
      .set('pageSize', `${pageParams.limit}`)
      .set('search', `${pageParams.search}`)
      .set('dateFrom', `${pageParams.dateFrom}`)
      .set('dateTo', `${pageParams.dateTo}`)
      .set('status', `${pageParams.status}`);
    return this.httpClient.get(this.apiUrl + '/order', { params, headers: this.headers})
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }

  couponlist(pageParams) {
    const params = new HttpParams()
      .set('orderBy', `${pageParams.orderBy}`)
      .set('orderDir', `${pageParams.orderDir}`)
      .set('pageNumber', `${pageParams.offset}`)
      .set('pageSize', `${pageParams.limit}`)
      .set('search', `${pageParams.search}`)
      .set('dateFrom', `${pageParams.dateFrom}`)
      .set('dateTo', `${pageParams.dateTo}`)
      .set('status', `${pageParams.status}`);
    return this.httpClient.get(this.apiUrl + '/order/couponused', { params, headers: this.headers})
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }

  statistics() {
    return this.httpClient.get(this.apiUrl + '/order/statistics', { headers: this.headers })
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }

  details(id: any) {
    return this.httpClient.get(this.apiUrl + '/order/' + id, { headers: this.headers })
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }

  update(id: any, updatedData: any) {
    return this.httpClient.put(this.apiUrl + '/order/' + id, updatedData, { headers: this.headers })
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }

  cancelOrder(id: any) {
    return this.httpClient.put(this.apiUrl + '/order/cancel/' + id, {}, { headers: this.headers })
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }
  create(updatedData: any) {
    return this.httpClient.post(this.apiUrl + '/order/', updatedData, { headers: this.headers })
      .pipe(
        map((response: Response) => response),
        catchError(error => this.errorHandlingService.handleError(error, ''))
      );
  }

  viewBundleOrders(pageIndex, pageSize, search, startDate, endDate) {
    const params = new HttpParams()
      .set('pageIndex', `${pageIndex}`)
      .set('pageSize', `${pageSize}`)
      .set('search', `${search}`)
      .set('startDate', `${startDate}`)
      .set('endDate', `${endDate}`)
    return this.httpClient.get(`${this.apiUrl}/order/bundle/orders`, { params, headers: this.headers }).pipe(map((response: Response) => response),
      catchError(error => this.errorHandlingService.handleError(error, error.error))
    );
  }

  subscribedUsersOrders(pageIndex, pageSize, search, startDate, endDate) {
    const params = new HttpParams()
      .set('pageIndex', `${pageIndex}`)
      .set('pageSize', `${pageSize}`)
      .set('search', `${search}`)
      .set('startDate', `${startDate}`)
      .set('endDate', `${endDate}`)
    return this.httpClient.get(`${this.apiUrl}/order/membership/orders`, { params, headers: this.headers }).pipe(map((response: Response) => response),
      catchError(error => this.errorHandlingService.handleError(error, error.error))
    );
  }


}
